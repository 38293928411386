import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { withPrefix } from 'gatsby'

const SecondPage = () => (
  <Layout>
    <SEO title="MHM 97" />
    <h1>MHM 97</h1>
    <div>
      <p>
        Palatkaa kanssani 1990-luvun puoliväliin. Hockey League Simulatorin
        henki leijaili hiljakseen vetten päällä, mutta muuten
        jääkiekkomanagerointirintamalla oli hiljaista. Taulukkolaskin HLS:llä
        sen minkä ehdin, ja näpertelin liiga- ja divarijoukkueet siihen ja Wayne
        Gretzky Hockey:hyn joka kausi. Kun minua huvitti manageroida futista,
        valintani olivat The Manager ja On The Ball -- ajattomia klassikkoja
        kumpikin.
      </p>

      <h2>Iikka Ojansivu Pro Hockey Manager</h2>

      <p>
        Ajatus jääkiekkomanageripelin tekemisestä kypsyi hitaasti, mutta
        varmasti. Ensimmäinen yritykseni oli{' '}
        <em>Iikka Ojansivu Pro Hockey Manager</em>, kaverin kiekkoharrastuksesta
        vuonna 1995 vääntämäni huono vitsi.
      </p>

      <p>
        Huomatkaa, että olin ~16-vuotias enkä ollut koskaan ohjelmoinut mitään
        kunnollista. Olin toki naputellut nassikkana Commodore kuusneloselle
        typeriä tekstipelejä, ja tästä mahtavasta asiantuntemuksesta sikisi
        parin joulun lomapäivän alussa ihka ensimmäinen esi-MHM.
      </p>

      <p>
        Kunnianhimoni suuruutta kuvastaa se, että palasin aiheeseen välittömästi
        <em>seuraavana jouluna</em>. Kirjoitin tuotoksen uusiksi, yhdellä erolla
        aikaisempaan: otin asioista vähän selvää. Tämän ansiosta onnistuin
        tekemään kahdentoista joukkueen sarjan kymmenen sijaan, ja{' '}
        uudelleenkäyttämään tiettyjä aspekteja lähdekoodista (toim. huom. no et
        kyllä kovin monia) raa'an ja hedelmättömän toistamisen sijaan. Nimesin
        tulokkaan
        <strong>Maso Software Hockey Manager 2</strong>:ksi. Brändini oli
        syntynyt.
      </p>

      <h2>MHM 97 syntyy</h2>

      <p>
        Jostain syystä innostuin kehittämään pelinraakiletta edelleen. Jossain
        vaiheessa vaihdoin kakkosen EA Sportsia mukaillakseni vuosiluvuksi, ja
        lähdekoodin koko kasvoi vuoden 1997 mittaan vapaa-aikani kustannuksella
        kolmestakymmenestä kilosta yli sataan.
      </p>

      <p>
        Jossain vaiheessa ystäväni, joita pommitin erilaisilla beta- ja
        "valmiilla" versioilla, ryhtyivät itse asiassa odottamaan ja kyselemään
        niitä. Aina kun tarvitsin peliin jotain uutta, jouduin opettelemaan
        vähän lisää ohjelmointia, joka taas mahdollisti uusien ominaisuuksien
        tekemisen. Reaktio oli autokatalyyttinen.
      </p>

      <p>
        Syksyllä 1997 päätin huvikseni julkaista pelini kaikelle kansalle.
        Työnsin <em>MHM 97 Final Beta</em>:nn purkkeihin (Internet ei ollut
        vielä ihan lyönyt läpi), ja jäin odottamaan en yhtään mitään. Pian
        kuitenkin sain sähköpostia, epäilen että kyseessä oli ensimmäinen
        sähköpostini ikinä koskaan, joku oli paitsi ladannut MHM 97:n myös{' '}
        <strong>pitänyt</strong>
        siitä. Olin ihmeissäni.
      </p>

      <p>
        Vielä enemmän olin ihmeissäni, kun löysin pelini Mikrobitistä kuukauden
        kotimaiseksi nimettynä. Kohkasin asiasta kaikille, jotka jaksoivat
        kuunnella, ja paiskoin kasaan pari uutta versiota. Lopulta uskalsin
        pyytää Deluxe-versiosta nimellistä korvausta. Muutamat suureksi
        yllätyksekseni olivat valmiit sen maksamaan.
      </p>

      <p>
        Enempää ei tarvittu. Kun innostun jostakin kunnolla, minulla on tapana
        maalailla pilvilinnoja. MHM 97:n jälkeen kuvittelin olevani jos en
        tuleva maailman suurin pelintekijä niin ainakin yksi suurimmista, ja
        uskoin eläväni tuotoksillani. Jätin epäselvät opiskelusuunnitelmani
        sikseen (en osannut oikein sanoa, mikä minua saattaisi ammattina
        kiinnostaa), ja päätin keskittyä täysillä pelintekijän uraani.
      </p>

      <p />

      <h2>Pelaa</h2>

      <p>
        Tarvitset Chromen, Firefoxin tai Safarin. Käyttöliittymä on
        parhaimmillaan kännykällä, kunnes toisin todistetaan.
      </p>

      <ul>
        <li>
          <a target="_blank" href="https://mhm97.mhm-online.org">
            Pelaa verkossa (early access)
          </a>
        </li>
      </ul>

      <p>
        Kiinnostaako tutkia mitä uusioversio on syönyt?
        <a target="_blank" href="https://github.com/mhm-online/mhm-97-remake">
          Lataa koodit Githubista!
        </a>
        !
      </p>

      <h2>Lataa</h2>

      <p>
        MHM 97 on DOS-peli. Tarvitset{' '}
        <a target="_blank" href="https://www.dosbox.com/">
          Dosboxin
        </a>{' '}
        pelataksesi sitä.
      </p>

      <ul>
        <li>
          <a href={withPrefix('/files/mhm97fb.zip')}>MHM 97 Final Beta</a>
        </li>
        <li>
          <a href={withPrefix('/files/mhm97dx.zip')}>MHM 97 Deluxe</a>
        </li>
      </ul>
    </div>

    <Link to="/">Takaisin</Link>
  </Layout>
)

export default SecondPage
